import React, { ReactElement } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PostsDistributive from '../components/posts-distributive';
import Carrousel from '../components/carousel';

import '../utils/normalize.css';
import '../utils/css/screen.css';
import { faPizzaSlice } from '@fortawesome/free-solid-svg-icons';

//TODO: Update this query with new data and add path to page when it is integrated in graphql
export const indexQuery = graphql`
  query allRecipes {
    carouselRecipesA: allStrapiRecipes(
      sort: { order: DESC, fields: source___date }
      limit: 3
      filter: {
        custom_thumbnail: { childImageSharp: { fluid: { src: { regex: "/" } } } }
        with_custom_thumbnail: { eq: true }
      }
    ) {
      edges {
        node {
          id
          title
          published_at(formatString: "DD/MM/YYYY")
          localImage {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          with_custom_thumbnail
          custom_thumbnail {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          chef {
            alias
            avatar {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            path
          }
          categories {
            name
            path
            id
          }
          fields {
            path
          }
          source {
            date(formatString: "DD, MMM y", locale: "es")
          }
        }
      }
    }
    carouselRecipesB: allStrapiRecipes(
      sort: { order: DESC, fields: source___date }
      limit: 3
      filter: { with_custom_thumbnail: { eq: false } }
    ) {
      edges {
        node {
          id
          title
          published_at(formatString: "DD/MM/YYYY")
          localImage {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          with_custom_thumbnail
          custom_thumbnail {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          chef {
            alias
            avatar {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            path
          }
          categories {
            name
            path
            id
          }
          fields {
            path
          }
          source {
            date(formatString: "DD, MMM y", locale: "es")
          }
        }
      }
    }
    allRecipes: allStrapiRecipes(sort: { order: DESC, fields: source___date }) {
      edges {
        node {
          id
          description
          title
          url_video
          published_at(formatString: "DD/MM/YYYY")
          localImage {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          with_custom_thumbnail
          custom_thumbnail {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          chef {
            alias
            avatar {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            path
          }
          duration
          fields {
            path
          }
          updated_at
          source {
            date(formatString: "DD, MMM y", locale: "es")
            like_count
            published_at(formatString: "DD/MM/YYYY")
            view_count
          }
          categories {
            name
            path
            id
          }
          difficulty {
            name
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        seoTags
        image
      }
    }
  }
`;

// TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
// FIXME: Add typing for Gatsby GrapthQL queries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BlogIndex: React.FC<any> = (): ReactElement => {
  return (
    <StaticQuery
      query={indexQuery}
      render={data => {
        const siteTitle = data.site.siteMetadata.title;
        const carouselPosts = data.carouselRecipesA.edges
          .concat(data.carouselRecipesB.edges)
          .sort((a, b) => {
            return new Date(a.node.source.date) > new Date(b.node.source.date) ? -1 : 1;
          })
          .slice(0, 3);
        const posts = data.allRecipes.edges;
        posts.map((post, index) => {
          carouselPosts.map(carouselPost => {
            if (post.node.id == carouselPost.node.id) {
              posts.splice(index, 1);
            }
          });
        });

        return (
          <Layout title={siteTitle}>
            <SEO
              title={siteTitle}
              description={data.site.siteMetadata.description}
              keywords={data.site.siteMetadata.seoTags}
            />
            <div className="post-feed wrapper-main">
              <Carrousel items={carouselPosts} />
              <PostsDistributive startingPost={0} posts={posts} />
            </div>
          </Layout>
        );
      }}
    />
  );
};

export default BlogIndex;
