import React, { ReactElement } from 'react';
import { CarouselProps } from './carousel.model';
import CarouselStyles from './carousel.module.scss';
import UiCarousel from 'react-material-ui-carousel';
import FeaturedImage from '../featured-image';

const Carousel: React.FC<CarouselProps> = (props: CarouselProps): ReactElement => {
  return (
    <UiCarousel
      className={CarouselStyles.container}
      index={0}
      animation="slide"
      navButtonsAlwaysVisible={true}
      indicators={false}
      autoPlay={false}
    >
      {props.items.slice(0, 3).map((item, i) => (
        <FeaturedImage
          disqusConfig={{ identifier: item.node.id, title: item.node.title }}
          post={item.node}
          postClass={`post`}
          key={i}
          hrefLink={item.node.fields.path}
          homePage={true}
          className={CarouselStyles.item}
        />
      ))}
    </UiCarousel>
  );
};

export default Carousel;
